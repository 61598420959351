import axios from 'axios'
import AuthToken from '@/auth/auth-token'
import { paginate } from './paginate'

class Relationship {
  constructor () {
    const relationship = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })

    this.relationship = relationship
  }

  async post (data) {
    await AuthToken.setAuth(this.relationship)

    return this.relationship({
      url: 'v1/party_relationship',
      method: 'POST',
      data: data
    })
  }

  async get (id, payload={}) {
    await AuthToken.setAuth(this.relationship)

    return this.relationship({
      url: `v1/party_relationship/${id}`,
      method: 'GET',
      params: payload
    })
  }

  async getList (id, limit, offset, payload={}, paging = true) {
    await AuthToken.setAuth(this.relationship)

    if (paging) {
      const url = `v1/party_relationship/${id}`
      return paginate(this.relationship, url, limit, offset, payload)
    }
    return this.relationship({
      url: `v1/party_relationship/${id}/${limit}/${offset}`,
      method: 'GET',
      params: payload
    })
  }

  async put (id, data) {
    await AuthToken.setAuth(this.relationship)

    return this.relationship({
      url: `v1/party_relationship/${id}`,
      method: 'PUT',
      data: data
    })
  }

  async delete (id) {
    await AuthToken.setAuth(this.relationship)

    return this.relationship({
      url: `v1/party_relationship/${id}`,
      method: 'DELETE'
    })
  }
}

export default new Relationship()
